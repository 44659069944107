import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobDetailDumpComponent } from './job-detail-dump.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@NgModule({
  declarations: [
    JobDetailDumpComponent
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
  exports: [
    JobDetailDumpComponent
  ]
})
export class JobDetailDumpModule { }
