import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable()
export class JobsService {
  private currentJob: number;
  private query = 'front/job-postings';
  private bookUrl = 'front/assessments';

  constructor(
    private http: HttpClient
  ) {
  }

  setCurrentJob(id: number) {
    this.currentJob = id;
  }

  getCurrentJob() {
    return this.currentJob;
  }
  getAllJobs() {
    return this.http.get<any>(this.query);
  }

  getJobs(divisionId: any, contractType: string, searchItem: any, searchType: any): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        division_id: divisionId,
        contract_type: contractType,
        search_item: searchItem,
        type : searchType,
      }
    });
    return this.http.get<any>(this.query, {params});
  }

  profilePercentage(): Observable<any> {
    return this.http.get<any>('profile/self');
  }

  getJob(id): Observable<any> {
    return this.http.get<any>(`${this.query}/${id}`);
  }

  addToWishList(id): Observable<any> {
    return this.http.post<any>(`${this.query}/${id}/add-to-wishlist`, '');
  }

  removeFromWishList(id): Observable<any> {
    return this.http.post<any>(`${this.query}/${id}/remove-from-wishlist`, '');
  }

  uploadAboutYou(data): Observable<any> {
    return this.http.post<any>(`${this.query}/${this.currentJob}/about-you`, data);
  }
  getAboutYou(id): Observable<any> {
    return this.http.get<any>(`${this.query}/${id}/about-you`);
  }
  getAboutYouVideo(jobId, userId): Observable<any> {
    const link = 'front/candidate-about'
    return this.http.get<any>(`${link}/${jobId}/${userId}`);
  }

  makeVideoUrl() {
    const accessToken = localStorage.getItem('access_token');
    return `${environment.apiUrl}/front/job-postings/${this.currentJob}/about-you?access_token=${accessToken}`;
  }

  bookJobs(posting_id ,slot_id, postingId ) {
    const body = { slot_id, posting_id };
    return this.http.post<any>(`${this.bookUrl}/${postingId}/book`, body);

  }

  getCompanies() {
    return this.http.get<any>(`companies`);
  }
}
