import {Component, OnInit, Input} from '@angular/core';
import {Job} from '../../models/Job.model';

@Component({
  selector: 'app-job-detail-dump',
  templateUrl: './job-detail-dump.component.html',
  styleUrls: ['./job-detail-dump.component.scss']
})
export class JobDetailDumpComponent implements OnInit {
  @Input() job: Job;

  constructor() {
  }

  ngOnInit() {
  }

}
